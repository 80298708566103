import React from 'react';
import PropTypes from 'prop-types';
import { base } from '@feast-it/pesto';

import Layout from '../containers/layout';
import {
  Client as PrismicClient,
  formatMetadata,
  hasBorderlessHeader,
  hasTransparentHeader,
} from '../modules/prismic/prismic';
import { getTier1Verticals } from '../modules/verticals';
import renderPrismicSections from '../containers/prismic';
import Meta from '../components/Meta';
import PrismicContext from '../context/prismic';
import { useBreakpoint } from '../hooks/media';

const Home = ({ page: { data }, metadata, tier1Verticals, preview }) => {
  const isDesktop = useBreakpoint(base.breakpoints.m);

  return (
    <Layout
      theme={'base'}
      borderlessHeader={hasBorderlessHeader(data.body)}
      transparentHeader={hasTransparentHeader(data.body, isDesktop)}
      prismicPreview={preview}
    >
      <PrismicContext.Provider value={{ tier1Verticals }}>
        <Meta {...metadata} />
        {renderPrismicSections(data.body)}
      </PrismicContext.Provider>
    </Layout>
  );
};

export const getStaticProps = async ({
  req,
  preview = false,
  previewData = {},
}) => {
  const page = await PrismicClient(req).getByUID(
    'dynamic_landing_new',
    'home',
    previewData?.ref ? { ref: previewData?.ref } : {}
  );

  const tier1Verticals = await getTier1Verticals();

  const metadata = await formatMetadata(page.data);

  return {
    props: {
      page,
      metadata,
      tier1Verticals,
      preview,
    },
    revalidate: 1,
  };
};

Home.propTypes = {
  page: PropTypes.object.isRequired,
  metadata: PropTypes.object.isRequired,
  tier1Verticals: PropTypes.array.isRequired,
  preview: PropTypes.bool,
};

export default Home;
